import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import styles from "./listaPrensa.module.css"

const ListaPrensa = (props) => {
    const data = useStaticQuery(graphql`
        query PressesQuery {
            allMarkdownRemark(filter: { fields: { sourceInstanceName: { eq: "presses" } } }) {
                edges {
                    node {
                        frontmatter {
                            title
                            platform
                            date(formatString: "DD/MM/YYYY")
                            source
                            link
                        }
                    }
                }
            }

            aprendolibre: file(absolutePath: { regex: "/aprendolibre.png/" }) {
                childImageSharp {
                    fixed(height: 40, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            puntajenacional: file(absolutePath: { regex: "/puntajenacional.png/" }) {
                childImageSharp {
                    fixed(height: 40, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            graduate: file(absolutePath: { regex: "/graduate.png/" }) {
                childImageSharp {
                    fixed(height: 40, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return (
        <div className={styles.articulos}>
            {data.allMarkdownRemark.edges.map((press, index) => {
                let plataforma = ""
                if (press.node.frontmatter.platform === "Aprendolibre")
                    plataforma = (
                        <Image
                            style={{
                                maxWidth: "150px"
                            }}
                            imgStyle={{
                                objectFit: "contain"
                            }}
                            fixed={data.aprendolibre.childImageSharp.fixed}
                        />
                    )
                if (press.node.frontmatter.platform === "Puntaje Nacional")
                    plataforma = (
                        <Image
                            style={{
                                maxWidth: "150px"
                            }}
                            imgStyle={{
                                objectFit: "contain"
                            }}
                            fixed={data.puntajenacional.childImageSharp.fixed}
                        />
                    )
                if (press.node.frontmatter.platform === "Graduate")
                    plataforma = (
                        <Image
                            style={{
                                maxWidth: "150px"
                            }}
                            imgStyle={{
                                objectFit: "contain"
                            }}
                            fixed={data.graduate.childImageSharp.fixed}
                        />
                    )
                if (props.limit && index >= props.limit) {
                    return ""
                } else {
                    return (
                        <div className={styles.articulo} key={index}>
                            <div className={styles.topArticulo}>{plataforma}</div>
                            <p className={styles.titulo}>
                                <a href={press.node.frontmatter.link} target="_blank" rel="noreferrer">
                                    {press.node.frontmatter.title}
                                </a>
                            </p>
                            <div className={styles.bottomArticulo}>
                                <span className={styles.fuente}>{press.node.frontmatter.source}</span>
                                <span className={styles.fecha}>{press.node.frontmatter.date}</span>
                            </div>
                        </div>
                    )
                }
            })}
        </div>
    )
}

export default ListaPrensa

import React from "react"
import InnerContainer from "./innerContainer"
import Image from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

const Contactanos = (props) => {
    const data = useStaticQuery(graphql`
        query ContactanosQuery {
            logo: file(absolutePath: { regex: "/icon.png/" }) {
                childImageSharp {
                    fixed(width: 120, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    let contactTo = "/contacto/"
    if (props.toPress) contactTo = "/contacto_prensa/"

    return (
        <InnerContainer>
            <div
                style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Image
                    fixed={data.logo.childImageSharp.fixed}
                    style={{
                        maxWidth: "100%",
                        marginBottom: "40px"
                    }}
                    imgStyle={{
                        width: "100%",
                        height: "auto"
                    }}
                />
                <Link
                    className="button-primary light"
                    to={contactTo}
                    state={{
                        modal: true
                    }}
                >
                    Contáctanos
                </Link>
            </div>
        </InnerContainer>
    )
}

export default Contactanos

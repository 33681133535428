import React from "react"
import { graphql, Link } from "gatsby"
import ListaPrensa from "../components/listaPrensa"
import Layout from "../components/layout"
import Contactanos from "../components/contactanos"
import styled from "styled-components"

const CenteredContent = styled.div`
    text-align: center;
    margin-bottom: 50px;
`

const ContactoSuccess = ({ location }) => {
    return (
        <div style={{ backgroundColor: "#f2f2f2" }}>
            <Layout location={location}>
                <CenteredContent>
                    <p>¡Muchas Gracias!</p>
                    <p>Pronto nos pondremos en contacto contigo.</p>
                    <Link to="/">Volver al inicio</Link>
                </CenteredContent>
            </Layout>
        </div>
    )
}

export default ContactoSuccess

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
